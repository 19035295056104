.transaction-image {
  width: 90px;
  position: absolute;
  right: -3px;
  top: -1px
}

.no-border-radius {
  border-radius: 0 !important;
}

.message-summary .badge{
  background: red;
}

.badgeIconView svg {
  height: 50px;
  width: 50px;
  color: #0c3582e1;
  cursor: pointer;
}

.badgeIconView{
    position:relative;
    padding:5;
    height: 60px;
    width: 60px;
}
.badgeIconView .badge {
    position: absolute !important;
    top: 1px !important;
    right: 1px !important;
    background: red !important;
}


.itemDetailsRow .col-sm-6:nth-child(n+3) {
  margin-top: 10px;
}
