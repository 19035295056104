@import "settings/variable"; //themes' colors

//base...
@import "generic/normalize.scss";
@import "generic/box-sizing.scss";
@import "./base/scaffolding"; // styles of base elements
@import "./base/typography"; // base styles of h1-h6, p, span

//components...
// @import "component/btn";
@import "./component/card";
@import "./component/check-box";
@import "./component/sidebar";
@import "./component/load";
@import "./component/form";

//pages...
@import "containers/account";

//objects...
// @import "objects/layout.scss";

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: gray !important;
    opacity: 1; /* Firefox */
}

i.circle-icon {
    display: inline-block;
    border-radius: 30px;
    box-shadow: 0px 0px 2px #888;
    padding: 0.3em 0.57em;
}

.theme-title {
    color: #0c3582e1 !important;
    font-weight: bold;
}
