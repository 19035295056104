.a {
    background-image: url("https://homesy-static.s3.eu-central-1.amazonaws.com/media/landing-bg.jpeg");
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.searchcategorys {
    display: flex;
    vertical-align: center;
}
.select-1 {
    background: white;
    width: 20%;
    outline: 0;
    height: 44px;
    border: 0;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    color: gray;
    border: 0;
    padding-left: 10px;
}
.container {
    padding: 0 !important;
    margin: 0 !important;
}
.search-1 {
    width: 70%;
    height: 44px;
    outline: 0;
    border: 0;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    padding-left: 10px;
}
/* .submit-btn-1 {
    width: 30%;
    outline: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
} */

.card-body {
    padding: 0px;
}
.homesy-h1 {
    color: white !important;
    text-align: center;
    line-height: 72px;
    font-size: 60px;
}
.homesy-h4 {
    color: white !important;
    text-align: center;
    line-height: 50px;
    font-size: 23px;
}
.adjust {
    width: 50%;
    margin: 0 auto;
    padding-top: 250px;
    margin: 0 auto;
    height: 100%;
    padding-bottom: 300px;
}

.dropbtn {
    background-color: #ffffff;
    color: #504c4c;
    padding: 12px 15px;
    border: 1px solid #504c4c78;
    font-size: 16px;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown:hover .dropbtn {
    background-color: #b7b7b7;
}
.nav-cs {
    display: flex;
    padding: 0 20px;
    width: 90%;
}
.filter-btn {
    float: right;
    margin-top: 3px;
}
.cs-1 {
    display: flex;
    width: 100%;
    border: 1px solid gainsboro;
    padding-top: 10px;
    margin-bottom: 16px;
    box-shadow: 4px 2px 4px gainsboro;
}
.input-s {
    width: 15%;
    margin: 10px;
}
.property-value {
    padding: 2em 0em 0em 2em;
}

.platform-logo {
    height: 50px;
    width: 50px;
    transition: 600ms;
    cursor: pointer;
}
.platform-logo:hover {
    transform: scale(1.1);
}

.img-houses {
    height: 150px;
    max-width: 150px;
    overflow: hidden;
    padding: 10px;
}
.right {
    float: right;
}

.btns-1 {
    width: 170px;
    margin: 20px 10px;
}
.br-1 {
    text-transform: uppercase;
}

.flex {
    width: 100%;
}

.c-title {
    text-transform: uppercase;
    color: #0c3582e1 !important;
    line-height: 2;
    height: 40px;
    overflow: hidden;
    margin-left: 10px;
}
.btn-ui {
    margin-left: 10px;
    padding: 5px;
    border-radius: 3px;
}
.mark-ui {
    font-size: 19px;
    font-weight: 400;
}
.card-summary {
    box-shadow: 1px 2px 4px gainsboro;
    position: relative;
}
.pd-15 {
    padding-top: 15px;
    padding-bottom: 15px;
}
.padding-side {
    padding: 2em 0em 2em 0em;
}
.property-value {
    padding: 2em 0em 0em 0em;
}

.pd-0 {
    padding: 0;
}

.col-3-short {
    width: 21%;
    max-width: 21%;
}

@media (max-width: 997px) {
    .img-houses {
        height: 122px;
        width: 122px;
    }
    .col-sm-12-1 {
        max-width: 100%;
        flex: 100%;
    }
}
@media (max-width: 767px) {
    .img-welt {
        height: 33px;
        width: 33%;
    }
    .row-block {
        display: block;
    }
    .col-3-1 {
        max-width: 100%;
        flex: 100%;
    }
    .col-9-1 {
        max-width: 100%;
        flex: 100%;
    }
    .img-houses {
        height: 100%;
        width: 100%;
        padding-top: 10px;
    }
    .col-4-1 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .input-s {
        width: 40%;
        margin: 10px;
        display: inline;
    }
    .cs-1 {
        display: block;
    }
    .nav-cs {
        display: inline;
        padding: 0;
    }
    .filter-btn {
        width: 40%;
        margin-right: 32px !important;
    }
    .image-height {
        height: auto;
    }
}
input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button {
    display: none;
}

@media screen and (min-width: 768px) {
    .container {
        width: 100%;
        max-width: 1630px;
        margin: 0 auto !important;
    }
}

.full-width {
    width: 100% !important;
}

::-webkit-scrollbar {
    width: 0px !important;
}

.custom-carousel .control-dots .dot {
    background-color: orange !important;
    width: 12px !important;
    height: 12px !important;
    box-shadow: 2px 2px 2px 8px rgba(0, 0, 0, 0.25);
}
