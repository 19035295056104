.energy_bar {
    border: 1px solid black;
    min-height: 40px;
    background-image: linear-gradient(
        to right,
        green,
        lightgreen,
        yellow,
        orange,
        red,
        brown
    );
}

.box-label {
    min-height: 40px;
}

.bar_label {
    display: inline-block;
    line-height: 50px;
    vertical-align: middle;
    text-align: right;
}

.bar_color {
    display: inline-block;
    line-height: 50px;
    vertical-align: middle;
    text-align: center;
    border-right: 1px solid black;
}

.before_bar {
    width: 2%;
}

.a_energy_bar_plus {
    width: 8.3%;
}

.a_energy_bar {
    width: 6.8%;
}

.b_energy_bar,
.c_energy_bar {
    width: 8.6%;
}

.d_energy_bar,
.e_energy_bar {
    width: 10.3%;
}

.f_energy_bar {
    width: 13.7%;
}

.g_energy_bar {
    width: 17.2%;
}

.h_energy_bar {
    width: 13.7%;
    border-right: none;
}

.before_bar_label {
    width: 0%;
}

.a_plus_label {
    width: 10.3%;
}

.a_label {
    width: 6.8%;
}

.b_label,
.c_label {
    width: 8.6%;
}

.d_label,
.e_label {
    width: 10.3%;
}

.f_label {
    width: 13.7%;
}

.g_label {
    width: 17.2%;
}

.h_label {
    width: 13.7%;
    border-right: none;
}

.pointer {
    position: relative;
    background-color: #000;
    color: #fff;
    max-width: 180px;
    padding: 1rem;
    transform: translateY(-1rem);
    border-radius: 0.35rem;
}

/* .pointer:before {
    content: "";
    position: absolute;
    border-left: 4px solid black;
    z-index: 1;
    height: 70px;
    bottom: -77.5px;
    left: 0;
} */

.pointer_symbol {
    content: "";
    background: black;
}

.energy_container {
    width: 100%;
    position: relative;
}
