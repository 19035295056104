.category-icon {
    font-size: 15px;
    margin: 0 5px;
    cursor: pointer;
    color: gray;
}

.category-icon-active {
    font-size: 15px;
    margin: 0 5px;
    cursor: pointer;
    color: #0c3582e1;
}
