.card {
    width: 100%;
    padding-bottom: 30px;
    height: 100%;
    border: none;
    background-color: transparent;

    &.card--not-full-height {
        height: auto;
    }
}

.card-body {
    padding: 30px;
    height: 100%;
    border-radius: 5px;

    @include themify($themes) {
        background-color: themed("colorBackground");
    }
}

.card__title {
    margin-bottom: 30px;
    text-transform: uppercase;
    position: relative;

    &:not(:first-child) {
        margin-top: 40px;
    }

    .subhead {
        text-transform: none;
        font-size: 12px;
        line-height: 18px;
        opacity: 0.7;
        margin-top: 3px;
    }

    * {
        margin-bottom: 0;
    }

    h5 {
        font-size: 13px;
    }
}

.squared-corner-theme {
    .card-body {
        border-radius: 0;
    }
}

.blocks-with-shadow-theme {
    .card-body {
        box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.06);
    }
}

.wizard {
    margin-top: -2rem;
}
.wizard ul.nav:before {
    content: " ";
    position: absolute;
    bottom: 1px;
    width: 100%;
    height: 1px;
    z-index: 0;
    background: #f3f3f3;
}
.wizard-default ul.nav li {
    position: relative;
    padding: 0.5rem 0.5rem;
    text-align: center;
}
.nav-tabs .nav-item {
    margin-bottom: 18px;
}
.wizard ul.nav {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding: initial;
    background: transparent none repeat 0 0 / auto auto padding-box border-box
        scroll;
    background: initial;
    position: relative;
    border: initial;
    margin-bottom: 1.5rem;
}
.wizard-default ul.nav.disabled .step-doing a,
.wizard-default ul.nav.disabled .step-doing a small,
.wizard-default ul.nav.disabled .step-doing a span {
    color: #3ea3fc !important;
}

.wizard-default ul.nav.disabled a,
.wizard-default ul.nav.disabled a:active,
.wizard-default ul.nav.disabled a:hover {
    cursor: default;
    color: #d7d7d7;
}

.nav-tabs .nav-link {
    border: initial;
    padding-top: 1rem;
    cursor: pointer;
}

.nav-link {
    display: block;
    padding: 0.5rem 1rem;
}
.wizard-default ul.nav li a:before {
    content: " ";
    position: absolute;
    margin-top: 10px;
    display: block;
    border-radius: 50%;
    color: #000;
    color: initial;
    border: none;
    text-decoration: none;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.wizard-default ul.nav li a:before {
    background: #f3f3f3;
    width: 18px;
    height: 18px;
    z-index: 1;
    bottom: -6px;
}
.wizard-default ul.nav li.step-doing a:after,
.wizard-default ul.nav li.step-done a:after {
    background: #3ea3fc;
    width: 10px;
    height: 10px;
    z-index: 2;
    bottom: -2px;
}
.wizard-default ul.nav li.step-doing a:after,
.wizard-default ul.nav li.step-done a:after,
.wizard-default ul.nav li a:before {
    content: " ";
    position: absolute;
    margin-top: 10px;
    display: block;
    border-radius: 50%;
    color: #000;
    color: initial;
    border: none;
    text-decoration: none;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}
.wizard-basic-step {
    min-height: 85px;
}
.justify-content-center {
    justify-content: center !important;
}
.wizard-default ul.nav li a:focus small,
.wizard-default ul.nav li a:focus span,
.wizard-default ul.nav li a small,
.wizard-default ul.nav li a span {
    color: #d7d7d7;
    text-align: center;
}
.wizard-buttons {
    display: flex;
}
.justify-content-center {
    justify-content: center !important;
}
.property-header {
    text-align: center !important;
    color: #70bbfc !important;
    font-weight: 500 !important;
    font-size: 15px !important;
}

.mg-top-60 {
    margin-top: 60px;
}
.form-control {
    font-size: 0.8rem;
    width: 300px;
}
label {
    margin-top: 0.5rem !important;
}
.form-control:focus {
    box-shadow: none;
}
.wizard-default ul.nav li a span {
    color: #6c757d;
}
.mr-1 {
    margin-right: 0.75rem !important;
}
.mg-left-3 {
    margin-left: 3px;
}
.mg-left-20 {
    margin-left: 20px;
}
.mg-top-05rem {
    margin-top: 0.65rem;
}

.flexbox {
    display: flex;
    margin-top: 6px;
}
.flex {
    width: 120px;
    margin-left: 8%;
}
.border-style {
    border: 1px solid #d8d8d8;
}
.icon-color {
    color: #0062cc;
}
.input-width-text {
    width: 100%;
}
.image-height {
    height: 450px;
}
.padding-1 {
    padding: 1em 1em 1em;
    border: 1px solid #d8d8d8;
}
.magrin-left {
    margin-left: 30px;
}
.padding-side {
    padding: 2em 0em 0em 2em;
}
.padding-buttom {
    padding-bottom: 20px;
}

.card-summary {
    border: 1px solid gainsboro;
    border-radius: 10px;
}
.pd-10 {
    padding-top: 10px;
    border: 2px solid orange;
}
.row {
    margin: 0 !important;
}
